import blog1 from '../img/blog/blog1.jpg'
import blog2 from '../img/blog/blog2.jpg'
import blog3 from '../img/blog/blog3.jpg'
import blog4 from '../img/blog/blog4.jpg'
import blog5 from '../img/blog/blog5.jpg'
import blog6 from '../img/blog/blog6.jpg'
import blog7 from '../img/blog/blog7.jpg'
import blog8 from '../img/blog/blog8.jpg'
import blog9 from '../img/blog/blog9.jpg'
import blog10 from '../img/blog/blog10.jpg'
import blog11 from '../img/blog/blog11.jpg'
import blog12 from '../img/blog/blog12.jpg'
import blog13 from '../img/blog/blog13.jpg'
import blog14 from '../img/blog/blog14.jpg'
import blog15 from '../img/blog/blog15.jpg'
import blog16 from '../img/blog/blog16.jpg'
import blog17 from '../img/blog/blog17.jpg'
import blog18 from '../img/blog/blog18.jpg'
import blog19 from '../img/blog/blog19.jpg'
import blog20 from '../img/blog/blog20.jpg'
import blog21 from '../img/blog/blog21.jpg'
import blog22 from '../img/blog/blog22.jpg'
import blog23 from '../img/blog/blog23.jpg'
import blog24 from '../img/blog/blog24.png'
import blog25 from '../img/blog/blog25.jpg'

import amalgamas from '../pdf/amalgamas-dentales.pdf';
import ultrasonido from '../pdf/ultrasonido.pdf';
import incluidos from '../pdf/que-son-los-dientes-incluidos.pdf';
import guardas from '../pdf/guardas-dentales.pdf';
import airFlow from '../pdf/airFlow.pdf';
import blanqueamiento from '../pdf/blanqueamiento-dental.pdf';
import mitosBlanqueamiento from '../pdf/mitos-y-realidades.pdf';
import elegirUnBuenDentista from '../pdf/elegir-un-buen-dentista.pdf';
import necesitasBrackets from '../pdf/necesitas-brackets.pdf';
import selladoresDentales from '../pdf/selladores-dentales.pdf';
import comoCuidarLosDientesDeLosPequenosEnHalloween from '../pdf/como-cuidar-los-dientes-de-los-pequenos-en-halloween.pdf';
import selladoresDeFosetasYFisuras from '../pdf/selladores-de-fosetas-y-fisuras.pdf';
import piercingsEnLaBoca from '../pdf/piercings-en-la-boca.pdf';
import edadesDeTusDientes from '../pdf/edades-de-tus-dientes.pdf';
import bracketsAutoligados from '../pdf/brackets-autoligados.pdf';
import universoBacteriano from '../pdf/universo-bacteriano.pdf';
import laEndodoncia from '../pdf/la-endodoncia.pdf';
import ortodonciaEnAdultos from '../pdf/ortodoncia-en-adultos.pdf';
import sonrisasEnLaInfancia from '../pdf/sonrisas_en_la_infancia.pdf';
import guiaCuidadoBrackets from '../pdf/guia_cuidado_brackets.pdf';
import beneficiosLimpiezaDental from '../pdf/beneficios_de_la_limpieza_dental.pdf';
import importanteUsoHiloDental from '../pdf/importante_uso_hilo_dental.pdf';
import reemplazoDeDientesPerdidosConImplantes from '../pdf/reemplazo-de-dientes-perdidos-con-implantes.pdf';
import conoceLosTratamientosParaCombatirLasCaries from '../pdf/conoce-los-tratamientos-para-combatir-las-caries.pdf';
import queTiposDeCepillosDentalesExisten from '../pdf/que-tipos-de-cepillos-dentales-existen.pdf';

export const articlesList = [
    {
        title: '¿QUÉ TIPOS DE CEPILLOS DENTALES EXISTEN?',
        desc: 'Los cepillos dentales son sin duda la herramienta esencial para mantener una buena higiene bucal. Hoy en día existen diversos modelos y diseños creados para distintas necesidades, por lo que es muy importante conocer cuál es el mejor cepillo para cada uno de nosotros.',
        img: blog25,
        artcle: '',
        url: '/queTiposDeCepillosDentalesExisten',
        pdf: queTiposDeCepillosDentalesExisten
    },
    {
        title: 'Conoce los tratamientos para combatir las caries',
        desc: 'Las caries dentales son uno de los problemas dentales más frecuentes y pueden afectar tanto adultos como a niños.',
        img: blog24,
        artcle: '',
        url: '/conoce-los-tratamientos-para-combatir-las-caries',
        pdf: conoceLosTratamientosParaCombatirLasCaries
    },
    {
        title: 'Reemplazo de dientes perdidos con implantes',
        desc: 'UNA SOLUCIÓN EFICIENTE Y DURADERA.',
        img: blog23,
        artcle: '',
        url: '/reemplazo-de-dientes-perdidos-con-implantes',
        pdf: reemplazoDeDientesPerdidosConImplantes
    },
    {
        title: '¿Por qué es importante usar hilo dental?',
        desc: 'Cepillarse los dientes después de cada comida es uno de los hábitos más importantes para la salud bucal.',
        img: blog22,
        artcle: '',
        url: '/importante_uso_hilo_dental',
        pdf: importanteUsoHiloDental
    },
    {
        title: 'Beneficios de la limpieza dental',
        desc: 'Asistir a la limpieza dental con tu dentista debe formar parte de tu rutina periódica, no solo como un compromiso de agenda, sino por los diversos beneficios que trae consigo.',
        img: blog21,
        artcle: '',
        url: '/beneficios_de_la_limpieza_dental',
        pdf: beneficiosLimpiezaDental
    },
    {
        title: 'Guía completa para el cuidado de tus brackets',
        desc: '¡Descubre cómo la ortodoncia puede transformar tu sonrisa! Este procedimiento busca corregir la posición de los dientes y la mandíbula para mejorar la función y estética de la boca.',
        img: blog20,
        artcle: '',
        url: '/guia_cuidado_brackets',
        pdf: guiaCuidadoBrackets
    },
    {
        title: '¡Sonrisas saludables desde la infancia!',
        desc: 'Descubre ¿qué es la odontopediatría?',
        img: blog19,
        artcle: '',
        url: '/sonrisas_en_la_infancia',
        pdf: sonrisasEnLaInfancia
    },
    {
        title: 'Ortodoncia en Adultos',
        desc: 'Rompiendo mitos y mejorando sonrisas.',
        img: blog18,
        artcle: '',
        url: '/ortodoncia-en-adultos',
        pdf: ortodonciaEnAdultos
    },
    {
        title: 'La Endodoncia',
        desc: '¿Qué es y sus cuidados posteriores?',
        img: blog17,
        artcle: '',
        url: '/la-endodoncia',
        pdf: laEndodoncia
    },
    {
        title: 'El fascinante universo bacteriano de tu boca',
        desc: '¡Bienvenido al viaje! Busca el mejor asiento cerca de ti y ponte cómodo, queremos que nos acompañes en este maravilloso viaje, donde exploremos juntos los secretos que guarda tu sonrisa.',
        img: blog16,
        artcle: '',
        url: '/universo-bacteriano',
        pdf: universoBacteriano
    },
    {
        title: 'Brackets Autoligados: Innovación para el futuro de tu sonrisa',
        desc: 'Desde tiempos inmemorables, la búsqueda de una sonrisa perfecta ha sido un anhelo arraigado en la humanidad.',
        img: blog15,
        artcle: '',
        url: '/brackets-autoligados',
        pdf: bracketsAutoligados
    },
    {
        title: 'Las edades de tus dientes tienen una historia que contar',
        desc: 'Durante las etapas de nuestras vidas los cambios son inminentes, cada edad representa diferentes riesgos o cuidados.',
        img: blog14,
        artcle: '',
        url: '/edades-de-tus-dientes',
        pdf: edadesDeTusDientes
    },
    {
        title: 'Piercings en la boca ¿Un peligro para la salud?',
        desc: 'Las perforaciones al igual que los tatuajes, son una práctica cada vez más común y aceptada.',
        img: blog13,
        artcle: '',
        url: '/piercings-en-la-boca',
        pdf: piercingsEnLaBoca
    },
    {
        title: '¿Cómo cuidar los dientes de los pequeños en Halloween?',
        desc: '¡Llegó el otoño! Con esta época inicia la recta final del año, que se traduce en fiestas, dulces y muchos eventos para adultos y niños.',
        img: blog11,
        artcle: '',
        url: '/como-cuidar-los-dientes-de-los-pequenos-en-halloween',
        pdf: comoCuidarLosDientesDeLosPequenosEnHalloween
    },
    {
        title: 'SELLADORES de fosetas y fisuras',
        desc: 'El tratamiento de selladores es el tratamiento de caries preventivo más efectivo que puede ofrecerse al paciente.',
        img: blog12,
        artcle: '',
        url: '/selladores-de-fosetas-y-fisuras',
        pdf: selladoresDeFosetasYFisuras
    },
    /*{
        title: 'Selladores dentales',
        desc: 'Hoy en día buscamos como dentistas erradicar la caries, una manera de lograrlo es a través de la prevención de la caries dental en sus diversas formas.',
        img: blog10,
        artcle: '',
        url: '/selladores-dentales',
        pdf: selladoresDentales
    },*/
    {
        title: '¿Necesitas brackets?',
        desc: 'SIGNOS Y SÍNTOMAS QUE TE AYUDARÁN A SABERLO',
        img: blog9,
        artcle: '',
        url: '/necesitas-brackets',
        pdf: necesitasBrackets
    },
    {
        title: '¿Sabes cómo elegir un buen dentista?',
        desc: 'Tal vez no sea fácil elegir con quién o a dónde acudir para cuidar o solucionar algún problema dental...',
        img: blog8,
        artcle: '',
        url: '/elegir-un-buen-dentista',
        pdf: elegirUnBuenDentista
    },
    {
        title: 'Mitos y realidades del blanqueamiento dental',
        desc: 'Existen muchos mitos circulando por internet o redes sociales donde aconsejan...',
        img: blog7,
        artcle: '',
        url: '/mitos-y-realidades',
        pdf: mitosBlanqueamiento
    },
    {
        title: '¿Qué es el blanqueamiento dental?',
        desc: 'Es un tratamiento de estética dental cuyo propósito es aclarar el color...',
        img: blog6,
        artcle: '',
        url: '/blanqueamiento-dental',
        pdf: blanqueamiento
    },
    {
        title: 'La mejor terapia de higiene dental AIR FLOW',
        desc: 'Hoy por hoy, la tecnología sigue avanzando y la limpieza dental...',
        img: blog5,
        artcle: '',
        url: '/air-flow',
        pdf: airFlow
    },
    {
        title: 'Guardas dentales',
        desc: 'Hoy en día, muchas personas -principalmente adolescentes y adultos- tienen un ritmo de vida lleno de actividades, compromisos…',
        img: blog4,
        artcle: '',
        url: '/guardas-dentales',
        pdf: guardas
    },
    {
        title: '¿Qué son los dientes incluidos?',
        desc: 'Los dientes incluidos, también conocidos como dientes retenidos, son aquellos que...',
        img: blog3,
        artcle: '',
        url: '/que-son-los-dientes-incluidos',
        pdf: incluidos
    },
    {
        title: 'Ultrasonido para cuidar y limpiar tus dientes',
        desc: 'Actualmente, el tratamiento de prevención más económico es la limpieza dental.',
        img: blog2,
        artcle: '',
        url: '/ultrasonido',
        pdf: ultrasonido
    },
    {
        title: 'AMALGAMAS DENTALES, un tema de polémica',
        desc: 'Actualmente existen diferentes opiniones acerca de usar o no usar amalgamas como restauración dental.',
        img: blog1,
        url: '/que-es-el-blanqueamiento-dental',
        pdf: amalgamas
    },
]